import { FlowBase, identificationTypes } from "./flow-base";
import * as events from '../events';

class VodacomZATier2 extends FlowBase {
    handleIntegratorIframeIdentity() {
        this.metadata.idStartTime = Date.now();
        const base = this.integratorIdentifyUrl.split('?')[0];
        this.integrator.customHeIframeIdentify(
            base,
            'he',
            {
                return: window.location.href,
                metadata: this.metadata
            }
        );
    }

    onSubscriptionExists(response) {
        this.tracker.track(events.SUBSCRIPTION_EXISTS, {
            data: {
                reference: response.reference,
            },
        });
        this.redirectToProduct(response.jwt);
    }

    onMessage(event, onSuccess, onError) {
        //check the iframe event and then call the correct one
        const res = event.data;
        if (res.status_code == 200) {
            onSuccess(res)
        } else if (res.status_code == 302) {
            if (res.jwt) {
                this.onSubscriptionExists(res)
            } else {
                onError(res)
            }
        } else {
            onError(res)
        }
    }

    handleCancelResponse() {
        this.logger.info('[Tier 2] User declined and is redirecting')
    }

    handleCancelError(error) {
        this.logger.error('[Tier 2] Error returned from cancel endpoint', error)
    }

    handleCancelRequest() {
        this.setFlowStep(this.flowSteps.SPINNER)

        if(!this.returnIsIdentifyCallFinished()){
            this.handleCancelResponse()
        }

        if (this.contentClick && this.userContentBlocked) {
            this.metadata.user_content_override = this.userContentOverride;
        } else {
            delete this.metadata.user_content_override;
        }
        
        const data = {
            fraud_detection_id: this.metadata.fraud_transaction_id,
            identity: this.identity || "unindentified",
            metadata: this.metadata,
            mixpanel_tracking_id: this.metadata.event_tracking_id,
        };


        window.setTimeout(() => {
            window.open('https://www.vodacom.co.za/vodacom/shopping/v/add-to-bill', '_self')
        }, 3500)
        this.logger.info('[Tier 2] Sending data to cancel', data)
        this.integrator.customCancelRequest(data, 'lp/tier2/cancel', this.handleCancelResponse.bind(this), this.handleCancelError.bind(this))
    }

    bindTrackingEvents() {
        this.dispatcher.addEventListener(events.CANCEL_CLICK, this.handleCancelRequest.bind(this))
        this.dispatcher.addEventListener(events.FRAUD_DETECTED, this.handleFraudDetected.bind(this));
        this.dispatcher.addEventListener(events.SUBSCRIPTION_FAILED, this.handleSubscriptionFailed.bind(this));
        this.dispatcher.addEventListener(events.SUBSCRIPTION_SUCCEEDED, this.handleSubscriptionSucceeded.bind(this));
    }

    handleSubscriptionFailed(event) {
        this.tracker.track(events.SUBSCRIPTION_FAILED, {
            data: {
                reference: event.reference,
                status_code: event.status_code,
                error: event.error,
                response_string: event.response_string,
                content_blocked: event.content_blocked
            }
        });
    }

    setInactiveTimeout() {
        if (this.inactiveTimeout) {
            if (!this.inactiveTimeoutSet) {
                this.timeout = window.setTimeout(() => {
                    this.handleSetPageStateTimeout()
                }, this.timeoutLimit);
                this.logger.debug(`Flow (${this.name}): set inactive timeout ${this.timeout}`);
                this.inactiveTimeoutSet = true
            }
        }
    }

    subscribeOnError(response) {
        this.uiControl.hideElement(this.uiControl.controls.spinner);
        if (response.status_code === 403) {
            this.logger.error(`[Subscribe Error] Flow (${this.name}): fraud detected`, response);
            this.uiControl.showErrorMessage('INTEGRATOR_ERROR_TIER_2_FRAUD', 'Retry');
            this.setFlowStep(this.flowSteps.FRAUD);
            this.dispatcher.dispatchEvent(events.FRAUD_DETECTED, {
                reference: response.reference,
                status_code: response.status_code,
            });
        } else if (response.status_code === 402) {
            this.logger.error(`[Subscribe Error] Flow (${this.name}): insufficent funds`, response);
            this.setFlowStep(this.flowSteps.SUBSCRIPTION_FAILED);
            this.dispatcher.dispatchEvent(events.SUBSCRIPTION_FAILED, {
                reference: response.reference,
                status_code: response.status_code,
                error: response.message || null,
                response_string: response.response_string || null,
                content_blocked: this.userContentBlocked
            });
            this.uiControl.displayCustomMessage('You have insufficient funds. Please recharge and try again');
        } else {
            this.logger.error(`[Subscribe Error] Flow (${this.name}): received error from integrator`, response);
            this.setFlowStep(this.flowSteps.SUBSCRIPTION_FAILED);
            this.dispatcher.dispatchEvent(events.SUBSCRIPTION_FAILED, {
                reference: response.reference,
                status_code: response.status_code,
                error: response.message || null,
                response_string: response.response_string || null,
                content_blocked: this.userContentBlocked
            });
            this.uiControl.showErrorMessage('INTEGRATOR_ERROR_TIER_2_FRAUD', 'Retry');
        }
    }

    identifyOnSuccess(response) {
        if(this.iframeTimeout){
            window.clearTimeout(this.iframeTimeout)
        }
        if (response.forbidden) {
            this.destroyPageAndDisplayContentBlockNotice()
            return;
        }
        var endtime = Date.now()
        this.identity = response.identity;
        this.identified = response.identified;
        this.identifyCallFinished = true;
        if (this.identified) {
            this.logger.debug('[Identity Success] id response', response);
            this.userContentBlocked = (typeof response.content_blocked !== 'undefined' && (response.content_blocked == 1 || response.content_blocked == "Y" || response.content_blocked == true))
            this.tracker.track(events.IDENTIFY_SUCCESS, {
                timedelta: endtime - this.metadata.idStartTime,
            });
            if (this.contentClick && this.userContentBlocked) {
                this.tracker.track(events.CONTENT_BLOCKED);
                this.setFlowStep(this.flowSteps.CONTENT_CLICK)
            } else {
                this.tracker.track(events.NOT_CONTENT_BLOCKED);
            }
        } else {
            this.logger.debug(`[Identity Failure] id response`, response);
            this.tracker.track(events.IDENTIFY_FAILURE, {
                reference: response.reference,
                status_code: response.status_code,
                start: this.metadata.idStartTime,
                end: endtime,
            });
            this.identifyBackup()
        }
    }

    identifyOnError(response) { // overridable, this should call identifybackup()
        if(this.iframeTimeout){
            window.clearTimeout(this.iframeTimeout)
        }
        
        this.identity = response.identity;
        this.identified = response.identified;
        this.identifyCallFinished = true;
        //change this to use our system
        this.logger.error(`[Identity Error] Flow (${this.name}): received error from integrator`, response);
        this.tracker.track(events.IDENTIFY_ERROR, {
            reference: response.reference,
            status_code: response.status_code,
        });
        this.identifyBackup()
    }

    destroyPageAndDisplayContentBlockNotice(){
        this.tracker.track(events.IDENTIFY_SUCCESS);
        this.tracker.track(events.FORBIDDEN);
        document.body.innerHTML = "";
        const notice = document.createElement('p');
        const pageName = typeof contentBlockPageName !== 'undefined' ? contentBlockPageName : 'this page';
        notice.innerHTML = `Your request to ${pageName} has been rejected due to the Content Block set on your Vodacom number. Please contact your Vodacom Administrator.`
        notice.style.fontSize = "20px";
        document.body.appendChild(notice);
    }

    setFlowStep(flowStep) {
        this.logger.debug(`Flow (${this.name}): changing step from ${this.step} to ${flowStep}`);
        this.step = flowStep;
        this.uiControl.setPageState(flowStep);
        //Some flowsteps are triggered in this flow for CSS purpose and therefore aren't included here. They can be added in the future though.
        switch (flowStep) {
            case this.flowSteps.CONFIRM:
                this.uiControl.hideElement(this.uiControl.controls.tryForFree)
                this.uiControl.hideElement(this.uiControl.controls.wifi);
                this.uiControl.hideElement(this.uiControl.controls.spinner);
                this.uiControl.showElement(this.uiControl.controls.confirm);
                this.uiControl.showElement(this.uiControl.controls.consent.consentTickbox);
                this.uiControl.showElement(this.uiControl.controls.consent.consentTickboxMessage);
                this.uiControl.hideElement(this.uiControl.controls.subscribe);
                this.uiControl.hideElement(this.uiControl.controls.contentClick.button);
                this.uiControl.hideElement(this.uiControl.controls.contentClick.container);
                this.uiControl.hideElement(this.uiControl.controls.contentClick.contentClickModalBackdrop)
                this.tracker.track(events.CTA_SHOWN)
                break;
            case this.flowSteps.CONTENT_CLICK:
                this.uiControl.hideElement(this.uiControl.controls.tryForFree)
                this.uiControl.hideElement(this.uiControl.controls.spinner);
                this.uiControl.hideElement(this.uiControl.controls.subscribe);
                this.uiControl.hideElement(this.uiControl.controls.confirm);
                this.uiControl.showElement(this.uiControl.controls.contentClick.button);
                this.uiControl.showElement(this.uiControl.controls.contentClick.container);
                this.uiControl.showElement(this.uiControl.controls.contentClick.contentClickModalBackdrop)
                break;
            case this.flowSteps.WIFI:
                this.uiControl.hideElement(this.uiControl.controls.consent.consentTickbox);
                this.uiControl.hideElement(this.uiControl.controls.consent.consentTickboxMessage);
                this.uiControl.hideElement(this.uiControl.controls.tryForFree)
                this.uiControl.hideElement(this.uiControl.controls.spinner);
                this.uiControl.hideElement(this.uiControl.controls.contentClick.button);
                this.uiControl.hideElement(this.uiControl.controls.contentClick.container);
                this.uiControl.hideElement(this.uiControl.controls.subscribe);
                this.uiControl.hideElement(this.uiControl.controls.confirm);
                this.uiControl.showElement(this.uiControl.controls.wifi);
                this.uiControl.hideElement(this.uiControl.controls.contentClick.contentClickModalBackdrop)
                break;
            case this.flowSteps.SPINNER:
                this.uiControl.hideElement(this.uiControl.controls.tryForFree)
                this.uiControl.showElement(this.uiControl.controls.spinner);
                this.uiControl.hideElement(this.uiControl.controls.contentClick.button);
                this.uiControl.hideElement(this.uiControl.controls.contentClick.container);
                this.uiControl.hideElement(this.uiControl.controls.subscribe);
                this.uiControl.hideElement(this.uiControl.controls.confirm);
                this.uiControl.hideElement(this.uiControl.controls.consent.consentTickbox);
                this.uiControl.hideElement(this.uiControl.controls.consent.consentTickboxMessage);
                this.uiControl.hideElement(this.uiControl.controls.contentClick.contentClickModalBackdrop)
                break;
            case this.flowSteps.SUBSCRIPTION_SUCCEEDED_CONTAINER:
                this.uiControl.showElement(this.uiControl.controls.success.container);
                break;
            case this.flowSteps.FRAUD:
            case this.flowSteps.SUBSCRIPTION_FAILED:
                this.uiControl.hideElement(this.uiControl.controls.tryForFree)
                this.uiControl.hideElement(this.uiControl.controls.spinner);
                this.uiControl.hideElement(this.uiControl.controls.contentClick.button);
                this.uiControl.hideElement(this.uiControl.controls.contentClick.container);
                this.uiControl.hideElement(this.uiControl.controls.subscribe);
                this.uiControl.hideElement(this.uiControl.controls.confirm);
                this.uiControl.hideElement(this.uiControl.controls.consent.consentTickbox);
                this.uiControl.hideElement(this.uiControl.controls.consent.consentTickboxMessage);
                this.uiControl.hideElement(this.uiControl.controls.contentClick.contentClickModalBackdrop)
                break;
            case this.flowSteps.TIMEOUT:
                this.uiControl.hideElement(this.uiControl.controls.tryForFree)
                this.uiControl.hideElement(this.uiControl.controls.spinner);
                this.uiControl.hideElement(this.uiControl.controls.contentClick.button);
                this.uiControl.hideElement(this.uiControl.controls.contentClick.container);
                this.uiControl.hideElement(this.uiControl.controls.subscribe);
                this.uiControl.hideElement(this.uiControl.controls.confirm);
                this.uiControl.hideElement(this.uiControl.controls.consent.consentTickbox);
                this.uiControl.hideElement(this.uiControl.controls.consent.consentTickboxMessage);
                this.uiControl.hideElement(this.uiControl.controls.contentClick.contentClickModalBackdrop)
                break;
        }
    }
}

const vodacomZATier2FlowModule = new VodacomZATier2('Vodacom ZA Tier 2', identificationTypes.IFRAME, {
    contentClick: true,
    subscribeEndpoint: 'lp/tier2/subscribe',
    inactiveTimeout: true,
})
window.FlowModule = vodacomZATier2FlowModule;
